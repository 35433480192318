body {
  background-color: #fafafa;
}

body .disable-text-selection {
  -moz-user-select:none; /* firefox */
  /*noinspection CssUnknownProperty*/
  -khtml-user-select: none; /* Konqueror HTML */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE */
  user-select: none; /* Standard syntax */
}

@font-face {
  font-family: "Eurostile";
  src: local("Eurostile"),
  url("./assets/font/eurostile/Eurostile-Regular.otf") format("truetype");
  font-weight: bold;
}


@font-face {
  font-family: "EurostileBold";
  src: local("EurostileBold"),
  url("./assets/font/eurostile/Eurostile-Bold.otf") format("truetype");
  font-weight: bold;
}